/* Calendar */

.monthly_calendar {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.monthly_calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.monthly_calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.monthly_calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.monthly_calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.monthly_calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.monthly_calendar .body .today {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #74c167 0%, #74c167 40%);
  border-image-slice: 1;
}

.monthly_calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}

.monthly_calendar .body {
  height: 60vh;
}

.monthly_calendar .body .cell {
  position: relative;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.monthly_calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.monthly_calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.monthly_calendar .body .row {
  border-bottom: 1px solid var(--border-color);
  min-height: calc(100% / 5);
}

.monthly_calendar .body .row:last-child {
  border-bottom: none;
}

.monthly_calendar .body .cell:last-child {
  border-right: none;
}

.monthly_calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.monthly_calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.monthly_calendar .body .cell .bg {
  z-index: 5;
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.monthly_calendar .body .cell:hover .bg,
.monthly_calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.monthly_calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}
